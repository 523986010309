/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'gatsby'
import './_breadcrumb.scss'

const SingleBlogBreadCrumb = ({ title, slug }) => (

  <section id="single-blog-breadcrumb">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <ul className="single-blog-breadcrumb-wrap">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>/</li>
            <li>
              <Link to="/blogs">Blog</Link>
            </li>
            {title && slug && (
              <>
                <li>/</li>
                <li>
                  <Link className="active-breadcrumb-link" to={`/blogs/${slug.toLowerCase()}`}>{title}</Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default SingleBlogBreadCrumb
