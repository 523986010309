/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-named-as-default-member */
import React from "react";
import { graphql } from "gatsby";
import SecondaryLayout from "../components/layout/secondary-layout";
import SEO from "../components/seo";
import SingleBlogBreadCrumb from "../components/single-blog/breadcrumb";
import BlogContent from "../components/single-blog/blog-content";
import { filterLanguage } from "../components/utils/language";
const BlogPost = ({ data }) => {
  const { nodes } = data.allContentfulBlog;
  const nodeslang = filterLanguage(false, nodes);
  const blogContent = nodeslang[0];

  return (
    <SecondaryLayout location={false}>
      <SEO
        title={`Blog | ${blogContent.title}`}
        description={blogContent.metaDescription}
      />
      <SingleBlogBreadCrumb title={blogContent.title} slug={blogContent.slug} />
      <BlogContent blogContent={blogContent} />
    </SecondaryLayout>
  );
};

export const query = graphql`
  query ContentfulBlogPostBySlug($slug: String!) {
    allContentfulBlog(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        title
        slug
        node_locale
        metaDescription
        authorImage {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        authorName
        articlePublishDate
        featuredImage {
          fluid {
            ...GatsbyContentfulFluid
          }
          file {
            url
            fileName
          }
        }
        content {
          childContentfulRichText {
            html
          }
        }

        category {
          title
        }
      }
    }
  }
`;

export default BlogPost;
