/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
/* eslint-disable global-require */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React, { Component } from "react";
import $ from "jquery";
import Img from "gatsby-image";
import { Link } from "gatsby";
import ScriptTag from "react-script-tag";
import Gist from "super-react-gist";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import RelatedBlogs from "../related-blogs";
import "./_blogContent.scss";
import ReactHtmlParser from "react-html-parser";
var converthtml;
class BlogContent extends Component {
  componentDidMount() {
    $("code")
      .parent()
      .css({ backgroundColor: "#f8f8f8", padding: "16px 24px" });
  }

  render() {
    const { blogContent } = this.props;

    return (
      <>
        <div>
          <ScriptTag
            isHydrating={true}
            type="text/javascript"
            src="https://gist.github.com/henkka01/dbda80ce5eb2b750168c2d7a6b5c030a.js"
          />
          ;
        </div>
        <section id="blog-content-head">
          <div className="container">
            <div className="row">
              <div className="col-md-2 blog-content-social-share-wrap">
                <p>Share:</p>
                <ul>
                  <li>
                    <FacebookShareButton
                      url={`https://banbanmedia.co/blogs/${blogContent.slug}`}
                      className="facebook"
                    >
                      <span className="social-icon">
                        <img
                          src={require("../../../../static/blogs/icons/facebook-icon.svg")}
                          alt="Facebook"
                        />
                      </span>
                    </FacebookShareButton>
                  </li>
                  <li>
                    <TwitterShareButton
                      url={`https://banbanmedia.co/blogs/${blogContent.slug}`}
                      className="twitter"
                      title=""
                    >
                      <span className="social-icon">
                        <img
                          src={require("../../../../static/blogs/icons/twitter-icon.svg")}
                          alt="Twitter"
                        />
                      </span>
                    </TwitterShareButton>
                  </li>
                  <li>
                    <LinkedinShareButton
                      url={`https://banbanmedia.co/blogs/${blogContent.slug}`}
                      className="linkedin"
                      title=""
                    >
                      <span className="social-icon">
                        <img
                          src={require("../../../../static/blogs/icons/linkedin-icon.svg")}
                          alt="Linkedin"
                        />
                      </span>
                    </LinkedinShareButton>
                  </li>
                </ul>
              </div>
              <div className="col-md-10 p-md-0">
                <div className="row justify-content-center">
                  <div className="col-md-1">
                    <Link
                      className="d-flex mb-md-0 mb-4 mt-md-0 mt-4"
                      to="/blogs"
                    >
                      <img
                        className="img-fluid"
                        src={require("../../../../static/blogs/icons/back-icon.svg")}
                        alt="BACK ICON"
                      />
                      <span className="blog-content-back-text">Back</span>
                    </Link>
                  </div>
                  <div className="col-md-11 mt-md-0 mt-3">
                    <ul className="blog-content-category-tag">
                      {blogContent.category.map((category, index) => (
                        <li key={index}>
                          <Link
                            to={`/blogs/${category.title
                              .replace(/\s+/g, "-")
                              .toLowerCase()}`}
                          >
                            {category.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <Link
                      className="blog-content-title"
                      to={`/blogs/${blogContent.slug.toLowerCase()}`}
                    >
                      <h1>{blogContent.title}</h1>
                    </Link>
                    <div className="blog-content-author-wrap mt-lg-4 d-flex">
                      <Img
                        style={{
                          position: "relative",
                          height: "34px",
                          width: "34px",
                          borderRadius: "50%",
                        }}
                        fluid={blogContent.authorImage.fluid}
                        alt="author"
                      />
                      <div className="blog-content-author-name-and-publish ml-3">
                        <h5>{blogContent.authorName}</h5>
                        <p>{blogContent.articlePublishDate}</p>
                      </div>
                    </div>
                    <div className="col-md-2 blog-content-social-share-wrap-mob">
                      <ul>
                        <li>
                          <FacebookShareButton
                            url={`https://banbanmedia.co/blogs/${blogContent.slug}`}
                            className="facebook"
                          >
                            <span className="social-icon">
                              <img
                                src={require("../../../../static/blogs/icons/facebook-icon.svg")}
                                alt="Facebook"
                              />
                            </span>
                          </FacebookShareButton>
                        </li>
                        <li>
                          <TwitterShareButton
                            url={`https://banbanmedia.co/blogs/${blogContent.slug}`}
                            className="twitter"
                            title=""
                          >
                            <span className="social-icon">
                              <img
                                src={require("../../../../static/blogs/icons/twitter-icon.svg")}
                                alt="Twitter"
                              />
                            </span>
                          </TwitterShareButton>
                        </li>
                        <li>
                          <LinkedinShareButton
                            url={`https://banbanmedia.co/blogs/${blogContent.slug}`}
                            className="linkedin"
                            title=""
                          >
                            <span className="social-icon">
                              <img
                                src={require("../../../../static/blogs/icons/linkedin-icon.svg")}
                                alt="Linkedin"
                              />
                            </span>
                          </LinkedinShareButton>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="blog-content-feature-img">
                  <Img
                    className="img-fluid w-100"
                    fluid={blogContent.featuredImage.fluid}
                    alt={blogContent.featuredImage.file.fileName}
                  />
                  <p>{blogContent.featuredImage.description}</p>
                </div>

                <div className="blog-content-text mx-auto ok">
                  {!!blogContent.content.childContentfulRichText.html &&
                    (() => {
                      try {
                        const arrayhtml = [];
                        var count = blogContent.content.childContentfulRichText.html
                          .toString()
                          .split("<blockquote>");

                        var result = count.map((x) => {
                          if (x.includes("</blockquote>")) {
                            console.log(
                              "url",
                              x
                                .split("</blockquote>")[0]
                                .split("url=&quot;")[1]
                                .split("&quot;")[0]
                            );
                            arrayhtml.push(
                              <Gist
                                url={
                                  x
                                    .split("</blockquote>")[0]
                                    .split("url=&quot;")[1]
                                    .split("&quot;")[0]
                                }
                              />
                            );

                            arrayhtml.push(
                              ReactHtmlParser(x.split("</blockquote>")[1])
                            );
                          } else {
                            arrayhtml.push(ReactHtmlParser(x));
                          }
                        });

                        return arrayhtml;
                      } catch (e) {
                        return ReactHtmlParser(
                          blogContent.content.childContentfulRichText.html
                        );
                      }
                    })()}
                </div>
                <hr className="blog-content-divider mx-auto" />
                {!!blogContent.code && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: document
                        .write(blogContent.code.content[0].content[0].value)
                        .replace(/\\n/g, "")
                        .replace(/\\/g, ""),
                    }}
                  ></div>
                )}
                <br />

                <div className="blog-content-category-tags mx-auto">
                  <ul>
                    {blogContent.category.map((category, index) => (
                      <li key={index}>
                        <Link
                          to={`/blogs/${category.title
                            .replace(/\s+/g, "-")
                            .toLowerCase()}`}
                        >
                          {category.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <RelatedBlogs
                  relatedBlogCategory={blogContent.category[0].title}
                />

                <hr className="blog-content-divider mx-auto mb-5" />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default BlogContent;
