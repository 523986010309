/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import React from 'react'
import Img from 'gatsby-image'
import { Link, useStaticQuery, graphql } from 'gatsby'
import './_relatedBlogs.scss'

const RelatedBlogs = ({ relatedBlogCategory }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulBlog {
        nodes {
          id
          title
          slug
          authorImage {
            fluid {
              src
              srcSet
            }
          }
          authorName
          articlePublishDate
          featuredImage {
            file {
              url
              fileName
            }
          }
          category {
            title
          }
        }
      }
    }
  `)
  const allBlogsData = data.allContentfulBlog.nodes

  const filteredBlogs = allBlogsData.filter((blog) => {
    const { category } = blog
    const match = category.some((cat) => cat.title === relatedBlogCategory)
    return match
  })

  return (
    <div className="row single-blog-related-articles">
      <div className="col-12 single-blog-related-articles-head">
        <h1>Read similar articles</h1>
      </div>
      {filteredBlogs.slice(0, 3).map((relatedPost) => (
        <div className="col-md-4 mt-3" key={relatedPost.id}>
          <Link className="related-post-feature-image" to={`/blogs/${relatedPost.slug.toLowerCase()}`}>
            <img className="img-fluid" src={relatedPost.featuredImage.file.url} alt={relatedPost.featuredImage.file.fileName} />
          </Link>
          <div className="row mt-3">
            <div className="col-12">
              <ul className="related-post-category-tag">
                {relatedPost.category.map((category, index) => (
                  <li key={index}>
                    <Link to={`/blogs/${category.title.replace(/\s+/g, '-').toLowerCase()}`}>
                      {category.title}
                    </Link>
                  </li>
                ))}
              </ul>
              <Link className="related-post-blog-title" to={`/blogs/${relatedPost.slug.toLowerCase()}`}>
                <h1>{relatedPost.title}</h1>
              </Link>
              <div className="related-post-author-wrap mt-lg-4 d-flex">
                <Img
                  style={{
                    position: 'relative', height: '34px', width: '34px', borderRadius: '50%',
                  }}
                  fluid={relatedPost.authorImage.fluid}
                  alt="author"
                />
                <div className="related-post-author-name-and-publish ml-3">
                  <h5>{relatedPost.authorName}</h5>
                  <p>{relatedPost.articlePublishDate}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default RelatedBlogs
